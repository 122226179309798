<template>
    <div class="container">
        <span v-if="item" class="text-h3">{{ item.title }}</span>

        <div class="clock">
            <label v-for="index in 12" v-bind:key="index" :style="getStyleI(index)" :class="getClassP(index % 12)">
                <v-tooltip top v-model="tooltip[index]">
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" :class="getClass(index % 12)" @click="openDialog(index)">
                            {{ index }}</span>
                    </template>
                    <span v-if="item">{{ getTitleHour(index) }}</span>
                </v-tooltip>
            </label>
            <div class="indicator">
                <span class="hand hour" ref="hourHand"></span>
                <span class="hand minute" ref="minuteHand"></span>
            </div>
        </div>

        <v-dialog v-model="dialog" width="50%">
            <v-card class="pa-4">
                <v-card-title>{{ dialogTitle }}</v-card-title>
                <v-card-text v-html="dialogText">
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { APIService } from "@/http/APIService.js";
const apiService = new APIService();

export default {
    name: 'clocks',
    data() {
        return {
            item: null,
            minDeg: 0,
            hourDeg: 0,
            dialog: false,
            dialogText: "",
            dialogTitle: "",
            tooltip: Array(13).fill(false)
        }
    },
    mounted() {
        this.init();
        this.getItem();
    },
    computed: {
        currentHour: function () {
            return Math.floor(this.hourDeg / 30);
        }
    },

    methods: {
        getTitleHour(hour) {
            let var_name_title = 'this.item.clock_' + hour + '_title';
            return eval(var_name_title);
        },

        openDialog(hour) {
            this.dialog = true;
            let var_name = 'this.item.clock_' + hour + '_text';
            let var_name_title = 'this.item.clock_' + hour + '_title';
            this.dialogText = eval(var_name);
            this.dialogTitle = eval(var_name_title);
        },
        getItem() {
            apiService.getClocksPage().then((response) => {
                this.item = response;
            });
        },

        getClass(hour) {
            if (Math.floor(this.hourDeg / 30) == hour) {
                return "text-h2 font-weight-bold clickable hourtext";
            } else {
                return "hourtext";
            }
        },

        getActiveHour() {
            return Math.floor(this.hourDeg / 30);
        },

        getClassP(hour) {
            if (Math.floor(this.hourDeg / 30) == hour) {
                return "front";
            } else {
                return "";
            }
        },

        getStyleI(hour) {
            return "--i: " + hour;
        },

        init() {
            const hourHand = this.$refs.hourHand;
            const minuteHand = this.$refs.minuteHand;

            const updateTime = () => {
                let active_hour = this.getActiveHour();

                for (let i = 0; i < 13; i++) {
                    this.tooltip[i] = false;
                }

                this.tooltip[active_hour] = true;

                if (active_hour == 0)
                    this.tooltip[12] = true;

                minuteHand.style.transform = `rotate(${this.minDeg}deg)`;
                let hourDegRound = Math.floor(this.hourDeg / 30) * 30;
                hourHand.style.transform = `rotate(${hourDegRound}deg)`;

                this.minDeg = (this.minDeg + 360 / (1000 / 30) / 5) % 360;
                this.hourDeg = (this.hourDeg + 360 / (1000 / 30) / (12 * 5)) % 360;
            };

            setInterval(updateTime, 30);
            updateTime();
        },
    },
    watch: {
    }
}
</script>

<style>
.blob {
    animation: pulse 1.1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    15% {
        transform: scale(1.15);
    }

    30% {
        transform: scale(1);
    }

    40% {
        transform: scale(1.1);
    }

    80% {
        transform: scale(1);
    }
}

.clickable {
    cursor: pointer !important;
}

.front {
    z-index: 100;
}

:root {
    --primary-color: #f6f7fb;
    --white-color: #fff;
    --black-color: #18191a;
    --red-color: #e74c3c;
}

body.dark {
    --primary-color: #242526;
    --white-color: #18191a;
    --black-color: #fff;
    --red-color: #e74c3c;
}

.container .clock {
    display: flex;
    height: 400px;
    width: 400px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: var(--white-color);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1), 0 25px 45px rgba(0, 0, 0, 0.1);
    position: relative;
}

.clock label {
    position: absolute;
    inset: 20px;
    text-align: center;
    transform: rotate(calc(var(--i) * (360deg / 12)));
}

.clock label .hourtext {
    display: inline-block;
    font-size: 30px;
    font-weight: 600;
    color: var(--black-color);
    transform: rotate(calc(var(--i) * (-360deg / 12)));
}

.container .indicator {
    position: absolute;
    height: 10px;
    width: 10px;
    display: flex;
    justify-content: center;
}


.indicator .hand {
    position: absolute;
    height: 130px;
    width: 4px;
    bottom: 0;
    border-radius: 25px;
    transform-origin: bottom;
    background: var(--red-color);
}

.hand.minute {
    height: 120px;
    width: 5px;
    background: var(--black-color);
}

.hand.hour {
    height: 100px;
    width: 8px;
    background: var(--black-color);
}
</style>